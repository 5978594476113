











import { Component, Vue, Ref, Watch, Mixins } from 'vue-property-decorator'
import StudentSidebar from '@/components/organisms/StudentSidebar.vue'
import moment from 'moment'
import LocalMoment from '@/components/atoms/LocalMoment.vue'

@Component({
  components: {
    StudentSidebar
  },
})
export default class StudentSchedule extends Mixins(LocalMoment) {
  private studentScheduleUrl = process.env.VUE_APP_MANAMIRU_URL + "/student?" + 
    "branchId=" + Vue.prototype.$cookies.get('dataGdls').branchId +
    "&&access-token=" + Vue.prototype.$cookies.get('authGdls').accessToken +
    "&&client=" + Vue.prototype.$cookies.get('authGdls').client +
    "&&uid=" + Vue.prototype.$cookies.get('authGdls').uid
}
