import { Component, Mixins, Vue } from 'vue-property-decorator'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'

@Component
export default class ClassModeChangeAble extends Mixins(ClassModeMethods, LessonStudent) {
  /**
   * クラスモード変更
   * cookieにセット（dataGdls.classMode）
   * APIリクエスト
   * @param classMode 通常授業: TJ, 定期テスト対策: TK, 講習会: KS, 入試対策: NY, 補助教材: HJ
   */
  protected async changeClassMode(classMode: string, changeSideMenuMode = false) {
    // 下記該当しない場合はreturn
    if (!this.isValidClassMode(classMode)) return

    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['classMode'] = classMode
    if (changeSideMenuMode) {
      cookie['sideMenuMode'] = classMode
    }
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())

    // APIリクエスト(補助教材の場合は除く)
    if (classMode == this.CLASS_MODE.ASSIST) return
    await Vue.prototype.$http.httpWithToken.patch(`/studentClassMode`, {
      classModeCode: classMode,
    })

    Vue.prototype.$logger.log(`-- hogehoge ${classMode}`)

    this.noticeOrStackCurrentPage({ classModeCode: classMode })
  }

  /**
   * cookieのclassModeとsideMenuModeの値をチェックしてセットする
   * @param isAssistMaterial true: 補助教材画面, false: 補助教材以外の画面, default: false
   */
  protected async checkClassModeAndSideMenuMode(isAssistMaterial = false) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')

    if (isAssistMaterial) {
      if (cookie.classMode !== this.CLASS_MODE.ASSIST) {
        // 補助教材画面でcookie.classModeが正しくなければセットする
        await this.changeClassMode(this.CLASS_MODE.ASSIST)
      }
    } else if (cookie.classMode && cookie.sideMenuMode && cookie.classMode !== cookie.sideMenuMode) {
      await this.changeClassMode(cookie.sideMenuMode)
    }
  }
}
